require("../css/app.scss");

const $ = require('jquery');
window.$ = $;

const videojs = require('video.js/dist/video');
window.videojs = videojs;


require("./jquery.visible.js");

require("../libs/slick/slick/slick.min");
require("./common");
