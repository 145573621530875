$(function() {

  $(document).ready(function(){
    var $slider = $('.main-slider').slick({
      arrows: false,
      dots: true,
      dotsClass: 'morouna-slick-dots'
    });

    $slider.find(".slick-slide").on("click", function(){
      $slider.slick("slickNext");
    });

  });

  // accordion
  var acc = document.getElementsByClassName("accordion");
  var i;

  for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function() {
      this.classList.toggle("active");
      var panel = this.lastElementChild;
      if (panel.style.maxHeight){
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
    });
  }

  // nav menu sliding
  $('#menu-button').click(function(){
    $('.nav-menu')[0].style.width = "250px";
    $(" body ")[0].style.marginLeft = "250px";
    $(" body ")[0].style.marginRight = "-250px";
    $('.mask')[0].style.display = "block";
  });

  $('#close-btn').click(function(){
    $('.nav-menu')[0].style.width = "";
    $(" body ")[0].style.marginLeft = "0";
    $(" body ")[0].style.marginRight = "0";
    $('.mask')[0].style.display = "none";
  });

});


// The debounce function receives our function as a parameter
const debounce = (fn) => {

  // This holds the requestAnimationFrame reference, so we can cancel it if we wish
  let frame;

  // The debounce function returns a new function that can receive a variable number of arguments
  return (...params) => {

    // If the frame variable has been defined, clear it now, and queue for next frame
    if (frame) {
      cancelAnimationFrame(frame);
    }

    // Queue our function call for the next frame
    frame = requestAnimationFrame(() => {

      // Call our function and pass any params we received
      fn(...params);
    });

  }
};

window.debounce = debounce;

// Reads out the scroll position and stores it in the data attribute
// so we can use it in our stylesheets
const storeScroll = () => {
  document.documentElement.dataset.scroll = window.scrollY;
}

// Listen for new scroll events, here we debounce our `storeScroll` function
document.addEventListener('scroll', debounce(storeScroll), { passive: true });

// Update scroll position for first time
storeScroll();